/* eslint-disable vars-on-top */
<template>
  <section>
    <div>
      <b-modal
        id="modal-center"
        ref="modal-center"
        centered
        title="Gravação de aulas"
        hide-footer
        ok-title="Entendi"
      >
        <h4>Como funciona?</h4>
        <b-card-text>
          Para ativar a gravação de aulas, autorize o acesso do navegador ao microfone e sua webcam.
        </b-card-text>
        <b-button
          class="mt-2"
          variant="primary"
          block
          @click="confirm()"
        >
          Entendi
        </b-button>
      </b-modal>
      <b-modal
        id="sucess_modal"
        ref="sucess_modal"
        centered
        header-text-variant="success"
        title="Sucesso!"
        hide-footer
        ok-title="Entendi"
      >
        <b-card-text>
          Gravação efetuada com sucesso! Agora você pode fazer download da sua aula.
        </b-card-text>
        <b-button
          class="mt-2"
          variant="success"
          block
          @click="download()"
        >
          Fazer download
        </b-button>
      </b-modal>
      <vue-record-video
        id="button_origin"
        @result="onResult"
        @stream="onStream"
      />
      <br>
      <div>
        <a
          v-show="false"
          id="download"
          variant="success"
          class="btn btn-success"
        >
          Baixar
        </a>
        <br>
      </div>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="9"
          xl="9"
        >
          <video
            id="screen"
            width="100%"
            autoplay="autoplay"
            controls
          />
        </b-col>
        <b-col
          sm="12"
          md="12"
          lg="3"
          xl="3"
        >
          <br>
          <video
            id="camera"
            width="100%"
            autoplay="autoplay"
            controls
          />
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      record: false,
      src: null,
      imageBase64: null,
      videoUrl: null,
      initRecord: false,
      recordedChunks: [],
      mediaRecorder: null,
      showModal: true,
    }
  },
  computed: {},
  watch: {
    src() {
      this.$refs.sucess_modal.show()
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-expressions
    this.$refs['modal-center'].show()
    // this.getScreen({ video: { cursor: 'always' }, audio: false })
    // this.getCamera({ audio: true, video: true })
  },
  methods: {
    async getCamera(constraints) {
      let stream = null
      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints)
        const camera = document.getElementById('camera')
        camera.srcObject = stream
        camera.onloadedmetadata = function (e) {
          camera.play()
        }
        /* use the stream */
      } catch (err) {
        /* handle the error */
      }
    },
    async getScreen(displayMediaOptions) {
      let captureStream = null

      try {
        captureStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
        const screen = document.getElementById('screen')
        screen.srcObject = captureStream
      } catch (err) {
        console.error(`Error: ${err}`)
      }
    },
    onResult(data) {
      console.log('The blob data:', data)
      console.log('Downloadable video', window.URL.createObjectURL(data))
      const downloadLink = document.getElementById('download')
      downloadLink.href = URL.createObjectURL(data)
      this.src = URL.createObjectURL(data)
      downloadLink.download = 'acetest.mp4'
    },
    confirm() {
      this.$refs['modal-center'].hide()
      this.getScreen({ video: { cursor: 'always' }, audio: false })
      this.getCamera({ audio: true, video: true })
    },
    download() {
      document.getElementById('download').click()
      this.$refs.sucess_modal.hide()
    },
    rec() {
      document.getElementById('button_origin').click()
      this.record = !this.record
    }
  }
}

</script>
<style>
.vue-video-recorder .recorder-icon{
  border-color: #28c76f !important;
  background-color: #28c76f !important;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.78rem 1.5rem;
  border-radius: 0.358rem;
  width: 150px;
  height: 40px;
}
</style>
